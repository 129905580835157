import SnipaRoutes from 'routes';
import Container from 'components/core/container';
import CopiableField from 'components/core/copiable-field';
import TabsSelect from 'components/core/tabs-select';
import RefreshButton from 'components/core/button/refresh-button';
import { ReactComponent as ProfileLogoDark } from 'assets/images/profile-logo-dark.svg';
import { ReactComponent as ProfileLogoLight } from 'assets/images/profile-logo-light.svg';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'providers/ThemeProvider';
import { Theme } from 'stores/ThemeStore';
import { bigNumberToStr } from 'utils/strings';
import { useTransactionHistoryStore } from 'providers/transaction-history/TransactionHistoryStoreProvider';
import { useProfilePortfolioStore } from '../providers/ProfilePortfolioStoreProvider';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useProfileAnalyticsStore } from '../providers/ProfileAnalyticsStoreProvider';
import { useSmMediaQuery } from 'utils/responsiveness';
import { showBetaFunctionality } from 'utils/beta';

const ProfileHeader = observer(
  ({ address }: { address?: string | undefined }) => {
    const { pathname } = useLocation();

    const theme = useTheme();
    const profilePortfolioStore = useProfilePortfolioStore();
    const transactionHistoryStore = useTransactionHistoryStore();
    const profileAnalyticsStore = useProfileAnalyticsStore();

    const isDesktop = useSmMediaQuery();

    const isLoading = useMemo(
      () =>
        profilePortfolioStore.loading ||
        transactionHistoryStore.loading ||
        profileAnalyticsStore.loading,
      [
        profileAnalyticsStore.loading,
        transactionHistoryStore.loading,
        profilePortfolioStore.loading,
      ]
    );

    const onRefresh = useCallback(() => {
      if (!address || isLoading) {
        return;
      }

      profilePortfolioStore.fetchPortfolio(address);

      if (pathname.includes(SnipaRoutes.profile.history.path)) {
        transactionHistoryStore.refresh();
      }

      if (pathname.includes(SnipaRoutes.profile.analytics.path)) {
        profileAnalyticsStore.refresh();
      }
    }, [
      address,
      isLoading,
      pathname,
      transactionHistoryStore,
      profilePortfolioStore,
      profileAnalyticsStore,
    ]);

    const profileTabs = useMemo(() => {
      const tabs = [{ id: 'portfolio', text: 'Portfolio', navLink: '' }];

      if (showBetaFunctionality) {
        tabs.push({
          id: 'history',
          text: 'History',
          navLink: SnipaRoutes.profile.history.path,
        });

        tabs.push({
          id: 'analytics',
          text: 'Analytics',
          navLink: SnipaRoutes.profile.analytics.path,
        });
      }

      return tabs;
    }, []);

    return (
      <div className='bg-navbar-mainBg'>
        <Container className='pt-6 flex flex-col gap-4 md:gap-6'>
          <div className='flex flex-col md:flex-row justify-between md:items-center gap-2 md:gap-4'>
            <div className='flex items-center gap-4'>
              {theme.current === Theme.Dark && (
                <ProfileLogoDark className='w-[44px] md:w-[82px] h-auto' />
              )}

              {theme.current === Theme.Light && (
                <ProfileLogoLight className='w-[44px] md:w-[82px] h-auto' />
              )}

              <CopiableField value={address} onCopyMessage='Address copied' />
            </div>

            <div className='flex items-center justify-between'>
              <span className='text-2xl font-semibold'>
                $
                {bigNumberToStr(
                  profilePortfolioStore.totalPortfolioUsdValue,
                  0
                )}
              </span>

              {!isDesktop && (
                <RefreshButton onRefresh={onRefresh} isLoading={isLoading} />
              )}
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <TabsSelect data={profileTabs} />

            {isDesktop && (
              <RefreshButton onRefresh={onRefresh} isLoading={isLoading} />
            )}
          </div>
        </Container>
      </div>
    );
  }
);

export default ProfileHeader;
