import SnipaRoutes from 'routes';
import Container from 'components/core/container';
import Button from 'components/core/button';
import SearchField from 'components/search-field';
import { useSmMediaQuery } from 'utils/responsiveness';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { cutString } from 'utils/strings';
import { FiLogOut } from 'react-icons/fi';
import { useCallback } from 'react';
import { useAuthStore } from 'providers/AuthProvider';
import { BsList } from 'react-icons/bs';
import { useLayoutStore } from 'providers/LayoutStoreProvider';

const Navbar = observer(() => {
  const auth = useAuthStore();
  const layoutStore = useLayoutStore();
  const isDesktop = useSmMediaQuery();

  const onEverLoginClick = useCallback(() => {
    auth.login();
  }, [auth]);

  const onEverLogoutClick = useCallback(() => {
    auth.logout();
  }, [auth]);

  const showSidebar = useCallback(() => {
    layoutStore.showSidebar();
  }, [layoutStore]);

  return (
    <div className='bg-navbar-mainBg border-b border-navbar-border'>
      <Container className='flex justify-between items-center py-4 sm:py-7 sm:gap-8'>
        {isDesktop ? (
          <div className='flex justify-center w-full'>
            <div className='w-full max-w-lg'>
              <SearchField />
            </div>
          </div>
        ) : (
          <div className='flex'>
            <button onClick={showSidebar}>
              <BsList className='text-3xl' />
            </button>
          </div>
        )}

        {auth.loggedIn && auth.account?.address ? (
          <div className='flex items-center whitespace-nowrap py-1 gap-4 md:gap-6'>
            <NavLink
              to={`/${SnipaRoutes.profile.path}/${auth.account.address}`}
              className='py-1 px-4 bg-success-1 text-dark text-sm font-semibold rounded-lg'
            >
              {cutString(auth.account.address, 8, 4)}
            </NavLink>
            <FiLogOut
              className='text-2xl cursor-pointer'
              onClick={onEverLogoutClick}
            />
          </div>
        ) : (
          <div className='shrink-0'>
            <Button
              className='font-normal'
              variant='primary'
              onClick={onEverLoginClick}
            >
              <span className='hidden md:inline'>Login via EVER Wallet</span>
              <span className='md:hidden'>Login</span>
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
});

export default Navbar;
